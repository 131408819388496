import Cookies from "js-cookie";
import { StorageStrategy } from "../abstracts";
import { GetError, HasError, RemoveError, SetError } from "../errors";

import { Sentry } from "~/decorators/sentry";

class CookieStorageStrategy extends StorageStrategy {
  @Sentry(GetError("CookieStorageStrategy"))
  public get<U>(key: string): U {
    return this.formatDataOnGet<U>(Cookies.get(key));
  }

  @Sentry(HasError("CookieStorageStrategy"))
  public has(key: string): boolean {
    return !!Cookies.get(key)?.length;
  }

  @Sentry(RemoveError("CookieStorageStrategy"))
  public remove(key: string): CookieStorageStrategy {
    Cookies.remove(key);

    return this;
  }

  @Sentry(SetError("CookieStorageStrategy"))
  public set<U>(key: string, data: U): CookieStorageStrategy {
    Cookies.set(key, this.formatDataOnSet<U>(data));

    return this;
  }
}

export { CookieStorageStrategy };

import merge from "lodash/merge";
import isNil from "lodash/isNil";

import { Destination } from "../../abstracts/Destination";
import type { EventPayload } from "../../types";

import { eventPayloadToMetaTrackEvent, eventPayloadToUserData, loadPixel } from "./helpers";
import type { MetaEvent } from "./types";

class MetaDestination extends Destination {
  public constructor(payload?: EventPayload) {
    super({ id: process?.env?.CX_META_ID ?? "578799829452599", name: "Meta" }, payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(window?.fbq);
  }

  public async loadIntegration(payload?: EventPayload): Promise<void> {
    this.enqueue(() => {
      window.fbq("init", this.id, eventPayloadToUserData(payload));
    });

    try {
      await loadPixel();
      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", error);
    }
  }

  public alias(): void {}

  public identify(payload: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to identify", this.id);
      return;
    }

    this.enqueue(() => {
      this._updateDangerouslyUserData(payload);
    });
  }

  public page(payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track page", this.id);
      return;
    }

    this.enqueue(() => {
      window.fbq("track", "PageView", {}, { eventID: payload?.messageId });
    });
  }

  public track(event: string, payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track", this.id);
      return;
    }

    this.enqueue(() => {
      eventPayloadToMetaTrackEvent(event, payload).forEach((eventData: MetaEvent) => {
        window.fbq(eventData.type, eventData.name, eventData.properties, { eventID: payload?.messageId });
      });
    });
  }

  private _updateDangerouslyUserData(payload: EventPayload): void {
    if (!this.isDestinationInstanceReady || !this.ready) {
      this.logError("Failed to set data", this.id);
      return;
    }

    // @ts-ignore
    window?.fbq?.instance?.pixelsByID?.[this.id]?.userData = merge({}, eventPayloadToUserData(payload));
  }
}

export { MetaDestination };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const SetError: (storageName: string) => SentryError = (storageName: string): SentryError => {
  return new SentryError()
    .setName(storageName)
    .setMessage("Set data into the storage has failed")
    .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
    .addFingerprint(`${storageName}.set`);
};

export { SetError };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const GetDataTypeError: SentryError = new SentryError()
  .setName("StorageStrategy")
  .setMessage("An attempt to define a data type has failed")
  .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
  .addFingerprint("StorageStrategy.getDataType");

export { GetDataTypeError };

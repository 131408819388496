import { AnalyticsBrowser } from "@segment/analytics-next";

import merge from "lodash/merge";
import isNil from "lodash/isNil";

import { Destination } from "../../abstracts/Destination";

import type { EventPayload } from "../../types";

import { eventPayloadToOptionsData, eventPayloadToPageData, eventPayloadToUserData } from "./helpers";

class SegmentDestination extends Destination {
  // looks like there is a bug/delay in the constructor of the segment sergice, cant be instantiated here
  private _segmentInstance!: AnalyticsBrowser;

  public constructor(payload?: EventPayload) {
    super({ id: process?.env?.CX_SEGMENT_ID ?? "otUVumdxAnpw8ZCZu6FFlJBBekED5Q26", name: "Segment" }, payload);
  }

  public get isDestinationInstanceReady(): boolean {
    return !!this.id.length && !isNil(this._segmentInstance);
  }

  public async loadIntegration(payload?: EventPayload): Promise<void> {
    this._segmentInstance = new AnalyticsBrowser();

    if (payload?.anonymousId?.length) {
      this._segmentInstance.setAnonymousId(payload.anonymousId);
    }

    if (payload?.userId?.length) {
      this._segmentInstance.identify(eventPayloadToUserData(payload));
    }

    try {
      await this._segmentInstance.load({ writeKey: this.id });
      this.initDestination();
    } catch (error: unknown) {
      this.logError("Failed to load", error);
    }
  }

  public alias(userId: string, previousId?: string): void {
    this._segmentInstance.alias(userId, previousId);
  }

  public identify(payload: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to identify", this.id);
      return;
    }

    if (!payload?.userId?.length) {
      this.logError("Cannot identify without userId property to identify", this.id);
      return;
    }

    this.enqueue(() => {
      this._segmentInstance.identify(
        payload?.userId,
        eventPayloadToUserData(payload),
        eventPayloadToOptionsData(payload),
      );
    });
  }

  public page(payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track page", this.id);
      return;
    }

    this.enqueue(() => {
      const properties: ReturnType<typeof eventPayloadToPageData> = eventPayloadToPageData(payload);
      const options: ReturnType<typeof eventPayloadToOptionsData> = eventPayloadToOptionsData(payload);

      if (
        payload.page.path.includes("/my-curex") ||
        payload.page.path.includes("/login") ||
        payload.page.path.includes("/logout")
      ) {
        this._segmentInstance.page("MyCurex", payload.page.title, properties, options);
        return;
      }

      if (
        payload.page.path.includes("/demographics") ||
        payload.page.path.includes("/checkout") ||
        payload.page.path.includes("/quiz")
      ) {
        this._segmentInstance.page("Intake Form", payload.page.title, properties, options);
        return;
      }

      // todo: test category
      this._segmentInstance.page("Webflow", payload.page.title, properties, options);
    });
  }

  public track(event: string, payload?: EventPayload): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to track", this.id);
      return;
    }

    this.enqueue(() => {
      this._segmentInstance.track(
        event,
        merge(eventPayloadToUserData(payload), payload?.properties),
        eventPayloadToOptionsData(payload),
      );
    });
  }
}

export { SegmentDestination };

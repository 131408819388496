const fromBinary = (binary: string): string => {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  const charCodes = new Uint16Array(bytes.buffer);

  let result: string = "";
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }

  return result;
};

export { fromBinary };

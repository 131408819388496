import get from "lodash/get";

import { SentryError } from "~/services/sentry/SentryError";
import { SentryService } from "~/services/sentry/SentryService";
import { isPromise } from "~/utils";

const Sentry = (sentryError: SentryError, { suppress = false } = {}): MethodDecorator => {
  return (
    target: Object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<any>
  ): TypedPropertyDescriptor<any> => {
    const reportError = (error: any, ...extras: any[]) => {
      sentryError.setError(error);

      if (Array.isArray(extras)) {
        extras.forEach((extra: any, index: number) => {
          const argName: string = get(extra, "constructor.name");
          const extraName: string = argName ? `<${argName}>arg[${index}]:` : `arg[${index}]:`;

          sentryError.addExtra(extraName, extra);
        });
      }

      SentryService.report(sentryError);

      return error;
    };

    const methodRef: Function = descriptor.value;

    descriptor.value = function (this: any, ...args: any[]): any {
      try {
        const result = methodRef.apply(this, args);

        if (isPromise(result)) {
          result.catch((error) => {
            if (suppress) {
              reportError(error, ...args);
              return Promise.resolve();
            }

            return Promise.reject(reportError(error, ...args));
          });
        }

        return result;
      } catch (error) {
        reportError(error, ...args);
        if (!suppress) {
          throw error;
        }
      }
    };

    return descriptor;
  };
};

export { Sentry };

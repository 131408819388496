import isEqual from "lodash/isEqual";
import negate from "lodash/negate";
import uniqWith from "lodash/uniqWith";

import { VisitModel } from "../models";
import { isModelValid } from "./isModelValid";
import { isVisitModelEmpty } from "./isVisitModelEmpty";
import { omitTraits } from "./omitTraits";

const filterUniqueModels = (models: VisitModel[] = []): VisitModel[] => {
  if (!models?.length) {
    return [];
  }

  return uniqWith(
    models.filter(isModelValid).filter(negate(isVisitModelEmpty)),
    (dataModel1: VisitModel, dataModel2: VisitModel) => {
      return isEqual(omitTraits(dataModel1), omitTraits(dataModel2));
    }
  );
};

export { filterUniqueModels };

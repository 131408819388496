import { stylesToString } from "~/utils/helpers/stylesToString";

import { Color, HexColor } from "~/mixins/atoms/UIColor/defaults";

const createAnchorTag = (
  name: string,
  link: string,
  target: string = "_blank",
  styles: Partial<CSSStyleDeclaration> = { color: HexColor[Color.PRIMARY] }
) => {
  return `<a class="d-inline-block" target="${target}" href="${link}" style="${stylesToString(styles)}">${name}</a>`;
};

export { createAnchorTag };

import { StorageStrategy } from "../abstracts";
import { GetError, HasError, RemoveError, SetError } from "../errors";
import { Sentry } from "~/decorators/sentry";

class LocalStorageStrategy extends StorageStrategy {
  @Sentry(GetError("LocalStorageStrategy"))
  public get<U>(key: string): U {
    return this.formatDataOnGet(localStorage.getItem(key));
  }

  @Sentry(HasError("LocalStorageStrategy"))
  public has(key: string): boolean {
    return key in localStorage;
  }

  @Sentry(RemoveError("LocalStorageStrategy"))
  public remove(key: string): LocalStorageStrategy {
    localStorage.removeItem(key);

    return this;
  }

  @Sentry(SetError("LocalStorageStrategy"))
  public set<U>(key: string, data: U): LocalStorageStrategy {
    localStorage.setItem(key, this.formatDataOnSet<U>(data));

    return this;
  }
}

export { LocalStorageStrategy };

const getColor = (colors: string[], index: number) => parseInt(colors[index], 16);

const convertToRGBA = (hex: string, opacity: number): string => {
  if (hex.length !== 7) {
    throw new Error("Only seven-digit hex colors are allowed.");
  }

  const splitColors = hex.replace("#", "").match(/.{1,2}/g);

  if (splitColors) {
    return `rgba(${getColor(splitColors, 0)}, ${getColor(splitColors, 1)}, ${getColor(splitColors, 2)}, ${opacity})`;
  }

  return "rgba(0, 0, 0, 0)";
};

export { convertToRGBA };

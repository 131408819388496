import { Url } from "./types";

import {
  IdTokenModel,
  PasswordlessSignUpModel,
  PasswordlessTokenModel,
  PasswordResetConfirmModel,
  PasswordResetModel,
  SignInModel,
  SignUpModel,
  TokenModel,
  TokenRedirectModel,
  TokenSocialModel,
} from "./models";

import { FetchService } from "~/services/fetch/Fetch";
import { RequestConfigModel } from "~/services/fetch/models";

class ApiAuthorization {
  private static _PREFIX: string = "/api/v2/";

  public createUrl(suffix: Url): string {
    return `${ApiAuthorization._PREFIX}${suffix}`;
  }

  public async autoLogin(data: PasswordlessTokenModel): Promise<TokenRedirectModel> {
    return await FetchService.postJson<TokenRedirectModel>(
      this.createUrl(Url.AUTO_LOGIN),
      data,
      new RequestConfigModel<TokenRedirectModel>({ model: new TokenRedirectModel() })
    );
  }

  public async passwordlessSignIn(data: PasswordResetModel): Promise<object> {
    return await FetchService.postJson<object>(
      this.createUrl(Url.PASSWORDLESS_SIGN_IN),
      data,
      new RequestConfigModel<TokenRedirectModel>({ allowEmptyResponse: true })
    );
  }

  public async passwordlessSignUp(data: PasswordlessSignUpModel): Promise<TokenModel> {
    return await FetchService.postJson<TokenModel>(
      this.createUrl(Url.SIGN_UP),
      data,
      new RequestConfigModel<TokenModel>({ model: new TokenModel() })
    );
  }

  public async resetPassword(data: PasswordResetModel): Promise<PasswordResetModel> {
    return await FetchService.postJson<PasswordResetModel>(
      this.createUrl(Url.PASSWORD_RESET),
      data,
      new RequestConfigModel<PasswordResetModel>({ model: new PasswordResetModel("", "") })
    );
  }

  public async resetPasswordConfirm(data: PasswordResetConfirmModel): Promise<TokenRedirectModel> {
    return await FetchService.postJson<TokenRedirectModel>(
      this.createUrl(Url.PASSWORD_RESET_CONFIRM),
      data,
      new RequestConfigModel<TokenRedirectModel>({ model: new TokenRedirectModel() })
    );
  }

  public async signIn(data: SignInModel): Promise<TokenModel> {
    return await FetchService.postJson<TokenModel>(
      this.createUrl(Url.SIGN_IN),
      data,
      new RequestConfigModel<TokenModel>({ model: new TokenModel() })
    );
  }

  public async signUp(data: SignUpModel): Promise<TokenModel> {
    return await FetchService.postJson<TokenModel>(
      this.createUrl(Url.SIGN_UP),
      data,
      new RequestConfigModel<TokenModel>({ model: new TokenModel() })
    );
  }

  public async refreshToken(data: TokenModel): Promise<TokenModel> {
    return await FetchService.postJson<TokenModel>(
      this.createUrl(Url.REFRESH_TOKEN),
      data,
      new RequestConfigModel<TokenModel>({ model: new TokenModel() })
    );
  }

  public async getTokenApple(data: IdTokenModel): Promise<TokenSocialModel> {
    return await FetchService.postJson<TokenSocialModel>(
      this.createUrl(Url.APPLE_SIGN_IN),
      data,
      new RequestConfigModel<TokenSocialModel>({ model: new TokenSocialModel() })
    );
  }

  public async getTokenGoogle(data: IdTokenModel): Promise<TokenSocialModel> {
    return await FetchService.postJson<TokenSocialModel>(
      this.createUrl(Url.GOOGLE_SIGN_IN),
      data,
      new RequestConfigModel<TokenSocialModel>({ model: new TokenSocialModel() })
    );
  }
}

const ApiAuthorizationService: ApiAuthorization = new ApiAuthorization();

export { ApiAuthorizationService };

import parseAsString from "lodash/toString";
import { FormatDataOnGetError, FormatDataOnSetError } from "../errors";

import { Sentry } from "~/decorators/sentry";
import { fromBinary, toBinary } from "~/utils";
import { SaveFormatModel } from "~/services/storage/models";

abstract class StorageStrategy {
  public abstract get<U>(key: string): U;

  public abstract has(key: string): boolean;

  public abstract remove(key: string): StorageStrategy;

  public abstract set<U>(key: string, data: U): StorageStrategy;

  @Sentry(FormatDataOnGetError)
  protected formatDataOnGet<U>(rawData: string): U {
    const { data = {} as U }: SaveFormatModel<U> = JSON.parse(fromBinary(atob(parseAsString(rawData))));

    return data;
  }

  @Sentry(FormatDataOnSetError)
  protected formatDataOnSet<U>(data: U): string {
    return btoa(toBinary(JSON.stringify(new SaveFormatModel({ data }))));
  }
}

export { StorageStrategy };

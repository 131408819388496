import { GetError, HasError, RemoveError, SetError } from "../errors";
import { StorageStrategy } from "../abstracts";

import { Sentry } from "~/decorators/sentry";

class SessionStorageStrategy extends StorageStrategy {
  @Sentry(GetError("SessionStorageStrategy"))
  public get<U>(key: string): U {
    return this.formatDataOnGet(sessionStorage.getItem(key));
  }

  @Sentry(HasError("SessionStorageStrategy"))
  public has(key: string): boolean {
    return key in sessionStorage;
  }

  @Sentry(RemoveError("SessionStorageStrategy"))
  public remove(key: string): SessionStorageStrategy {
    sessionStorage.removeItem(key);

    return this;
  }

  @Sentry(SetError("SessionStorageStrategy"))
  public set<U>(key: string, data: U): SessionStorageStrategy {
    sessionStorage.setItem(key, this.formatDataOnSet<U>(data));

    return this;
  }
}

export { SessionStorageStrategy };

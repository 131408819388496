import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import pickBy from "lodash/pickBy";

class UtmModel {
  public coupon?: string;

  // click id
  public dclid?: string;
  public fbclid?: string;
  public gclid?: string;
  public msclkid?: string;
  public rdtclid?: string; // todo: remove it after 15-may-2025, this is a duplicate of a real pixel name rdt_cid
  public rdt_cid?: string;
  public irclickid?: string;
  public sccid?: string;
  public ttclid?: string;

  // utm
  public utmCampaign?: string;
  public utmContent?: string;
  public utmId?: string;
  public utmMedium?: string;
  public utmSource?: string;
  public utmTerm?: string;
  public utmTest?: string;

  public constructor(source: Partial<UtmModel> = {}) {
    merge(this, cloneDeep(pickBy(source, Boolean)));
  }
}

export { UtmModel };

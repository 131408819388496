enum QueryParameter {
  COUPON = "coupon",
  DCLID = "dclid",
  FBCLID = "fbclid",
  GCLID = "gclid",
  MSCLKID = "msclkid",
  RDTCLID = "rdt_cid", // todo: remove it after 15-may-2025, this is a duplicate of a real pixel name rdt_cid
  RDT_CID = "rdt_cid",
  IRCLICKID = "irclickid",
  SCCID = "sccid",
  TTCLID = "ttclid",
  UTM_CAMPAIGN = "utm_campaign",
  UTM_CONTENT = "utm_content",
  UTM_ID = "utm_id",
  UTM_MEDIUM = "utm_medium",
  UTM_SOURCE = "utm_source",
  UTM_TERM = "utm_term",
  UTM_TEST = "utm_test",
}

export { QueryParameter };

import parseAsString from "lodash/toString";

import { UtmModel } from "../models";
import { QueryParameter } from "../types";
import { isUtmModelEmpty } from "./isUtmModelEmpty";

import { getFromQuery } from "~/utils";

const createUtmModelFromQueryParameters = (): UtmModel | void => {
  if (!window?.location?.href?.includes("?") && !document?.referrer?.includes("?")) {
    // nothing in the query
    return;
  }

  const utmModelFromUrl: UtmModel = new UtmModel({
    coupon: getFromQuery(QueryParameter.COUPON),
    // click id
    dclid: getFromQuery(QueryParameter.DCLID),
    fbclid: getFromQuery(QueryParameter.FBCLID),
    gclid: getFromQuery(QueryParameter.GCLID),
    msclkid: getFromQuery(QueryParameter.MSCLKID),
    rdtclid: getFromQuery(QueryParameter.RDTCLID),
    rdt_cid: getFromQuery(QueryParameter.RDT_CID),
    irclickid: getFromQuery(QueryParameter.IRCLICKID),
    sccid: getFromQuery(QueryParameter.SCCID),
    ttclid: getFromQuery(QueryParameter.TTCLID),
    // utm
    utmCampaign: getFromQuery(QueryParameter.UTM_CAMPAIGN),
    utmContent: getFromQuery(QueryParameter.UTM_CONTENT),
    utmId: getFromQuery(QueryParameter.UTM_ID),
    utmMedium: getFromQuery(QueryParameter.UTM_MEDIUM),
    utmSource: getFromQuery(QueryParameter.UTM_SOURCE),
    utmTerm: getFromQuery(QueryParameter.UTM_TERM),
    utmTest: getFromQuery(QueryParameter.UTM_TEST),
  });

  if (!isUtmModelEmpty(utmModelFromUrl)) {
    return utmModelFromUrl;
  }

  const referrer: string = parseAsString(document?.referrer);
  const referrerValidAndHasSearchString: boolean =
    referrer.includes("getcurex.com?") || referrer.includes("getcurex.com/?");

  if (!referrerValidAndHasSearchString) {
    return;
  }

  const searchString: string = parseAsString(referrer.split("?")?.[1]);
  const utmModelFromReferrer: UtmModel = new UtmModel({
    coupon: getFromQuery(QueryParameter.COUPON, searchString),
    // click id
    dclid: getFromQuery(QueryParameter.DCLID, searchString),
    fbclid: getFromQuery(QueryParameter.FBCLID, searchString),
    gclid: getFromQuery(QueryParameter.GCLID, searchString),
    msclkid: getFromQuery(QueryParameter.MSCLKID, searchString),
    rdtclid: getFromQuery(QueryParameter.RDTCLID, searchString),
    rdt_cid: getFromQuery(QueryParameter.RDT_CID, searchString),
    irclickid: getFromQuery(QueryParameter.IRCLICKID, searchString),
    sccid: getFromQuery(QueryParameter.SCCID, searchString),
    ttclid: getFromQuery(QueryParameter.TTCLID, searchString),
    // utm
    utmCampaign: getFromQuery(QueryParameter.UTM_CAMPAIGN, searchString),
    utmContent: getFromQuery(QueryParameter.UTM_CONTENT, searchString),
    utmId: getFromQuery(QueryParameter.UTM_ID, searchString),
    utmMedium: getFromQuery(QueryParameter.UTM_MEDIUM, searchString),
    utmSource: getFromQuery(QueryParameter.UTM_SOURCE, searchString),
    utmTerm: getFromQuery(QueryParameter.UTM_TERM, searchString),
    utmTest: getFromQuery(QueryParameter.UTM_TEST, searchString),
  });

  if (!isUtmModelEmpty(utmModelFromReferrer)) {
    return utmModelFromReferrer;
  }
};

export { createUtmModelFromQueryParameters };

import merge from "lodash/merge";

import { currentTimestamp } from "../helpers";

class SaveFormatModel<T> {
  public data: T;
  public lastModified: number = currentTimestamp();

  public constructor(sourceData: Partial<SaveFormatModel<T>> = {}) {
    merge(this, sourceData);
  }
}

export { SaveFormatModel };

import { StorageStrategy } from "./abstracts";
import {
  CookieStorageStrategy,
  LocalStorageStrategy,
  MemoryStorageStrategy,
  SessionStorageStrategy,
} from "./strategies";
import { Mode } from "./types";

import { isCookiesStorageSupported } from "~/utils/tests/isCookiesStorageSupported";
import { isLocalStorageSupported } from "~/utils/tests/isLocalStorageSupported";
import { isSessionStorageSupported } from "~/utils/tests/isSessionStorageSupported";

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";
import { SentryService } from "~/services/sentry/SentryService";

// todo: caching is not ideal, as one of the tab might change and another might have things in cache

class Storage {
  protected _writeMode: Mode = Mode.CACHE;
  protected _storage: StorageStrategy;
  private _cache: MemoryStorageStrategy = new MemoryStorageStrategy();

  public constructor() {
    this.init();
  }

  public init(): void {
    this._determineWriteMode();
  }

  public set<U>(key: string, data: U): Storage {
    // this._cache.set<U>(key, data);
    this._storage.set<U>(key, data);

    return this;
  }

  /**
   * If key:value is not present in any storage void will be returned
   * @template U
   * @returns {(U | void)}
   */
  public get<U>(key: string): U | void {
    /*     if (this._cache.has(key)) {
          return this._cache.get<U>(key);
        } */

    try {
      // this._cache.set<U>(key, data);

      return this._storage.get<U>(key);
    } catch (error) {
      SentryService.report(
        new SentryError()
          .addExtra("key", key)
          .addFingerprint("Storage.get")
          .addTag(SentryTagKey.METHOD, SentryScope.STORAGE)
          .setError(error)
          .setMessage("Failed to get data from Storage")
          .setName("Storage"),
      );
    }
  }

  public remove(key: string): Storage {
    // this._cache.remove(key);
    this._storage.remove(key);

    return this;
  }

  public has(key: string): boolean {
    return /* this._cache.has(key) ||  */ this._storage.has(key);
  }

  protected _determineWriteMode(): void {
    if (isLocalStorageSupported()) {
      this._writeMode = Mode.LOCAL_STORAGE;
      this._storage = new LocalStorageStrategy();
      return;
    }

    if (isCookiesStorageSupported()) {
      this._writeMode = Mode.COOKIE;
      this._storage = new CookieStorageStrategy();
      return;
    }

    if (isSessionStorageSupported()) {
      this._writeMode = Mode.SESSION_STORAGE;
      this._storage = new SessionStorageStrategy();
      return;
    }

    this._writeMode = Mode.CACHE;
    this._storage = this._cache;
  }
}

const StorageService: Storage = new Storage();

export { StorageService };

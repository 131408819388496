import type { EventPayload } from "../types";

import type { QueryStorageService } from "~/services/QueryStorage/QueryStorage";

import { cleanObject } from "~/utils/filters/cleanObject";

const getCampaignFromQueryStorage = (queryStorage: typeof QueryStorageService): EventPayload["context"]["campaign"] => {
  if (!queryStorage || !queryStorage?.visitModel) {
    return {};
  }

  return cleanObject({
    coupon: queryStorage.visitModel.data.coupon,
    // click ids
    dclid: queryStorage.visitModel.data.dclid,
    fbclid: queryStorage.visitModel.data.fbclid,
    gclid: queryStorage.visitModel.data.gclid,
    msclkid: queryStorage.visitModel.data.msclkid,
    rdtclid: queryStorage.visitModel.data.rdtclid,
    rdt_cid: queryStorage.visitModel.data.rdt_cid,
    irclickid: queryStorage.visitModel.data.irclickid,
    sccid: queryStorage.visitModel.data.sccid,
    ttclid: queryStorage.visitModel.data.ttclid,
    // utm
    content: queryStorage.visitModel.data.utmContent,
    id: queryStorage.visitModel.data.utmId,
    medium: queryStorage.visitModel.data.utmMedium,
    name: queryStorage.visitModel.data.utmCampaign,
    source: queryStorage.visitModel.data.utmSource,
    term: queryStorage.visitModel.data.utmTerm,
    test: queryStorage.visitModel.data.utmTest,
  });
};

export { getCampaignFromQueryStorage };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const FormatDataOnSetError: SentryError = new SentryError()
  .setName("StorageStrategy")
  .setMessage("Parse data before set in store failed")
  .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
  .addFingerprint("StorageStrategy.formatDataOnSet");

export { FormatDataOnSetError };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const GetError: (storageStrategyName: string) => SentryError = (storageStrategyName: string): SentryError => {
  return new SentryError()
    .setName(storageStrategyName)
    .setMessage("Get data from storage has failed")
    .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
    .addFingerprint(`${storageStrategyName}.get`);
};

export { GetError };

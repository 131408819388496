import merge from "lodash/merge";
import type { EventPayload } from "../types";

import { TaskQueue } from "~/services/task/TaskQueue";
import type { PromiseFunction } from "~/services/task/types";

import { isPromise } from "~/utils/validation/isPromise";
import { syncFunctionToAsyncFunction } from "~/utils/converters/syncFunctionToAsyncFunction";

interface DestinationConfiguration {
  id: string;
  name: string;

  [key: string]: any;
}

abstract class Destination<T extends DestinationConfiguration = DestinationConfiguration> {
  private _configuration!: T;
  private _tasksQueue: TaskQueue = new TaskQueue(false);

  /**
   * Create a destination
   *
   * @param {Partial<T>} configuration - Destination-specific params
   * @param {EventPayload} payload - First payload to initiate destination with
   */
  constructor(configuration: Partial<T>, payload?: EventPayload) {
    this._configuration = merge({ id: "unknown", name: "unknown" }, configuration) as T;

    this.loadIntegration(payload);
  }

  /**
   * Destination ID if exists.
   */
  public get id(): Destination["_configuration"]["id"] {
    return this._configuration.id;
  }

  /**
   * Destination Name
   */
  public get name(): Destination["_configuration"]["name"] {
    return this._configuration.name;
  }

  private _ready: boolean = false;

  /**
   * Is the destination successfully initiated on the website
   */
  public get ready(): Destination["_ready"] {
    return this._ready;
  }

  public abstract get isDestinationInstanceReady(): boolean;

  public abstract identify(payload: EventPayload): void;

  public abstract track(event: string, payload?: EventPayload): void;

  public abstract page(payload: EventPayload): void;

  public abstract alias(userId: string, previousId?: string, payload?: EventPayload): void;

  public abstract loadIntegration(payload?: EventPayload): void;

  public initDestination(): void {
    if (!this.isDestinationInstanceReady) {
      this.logError("Failed to init", this.id);
      return;
    }

    this.setReady(true);
  }

  public setReady(ready: boolean = false): void {
    this._ready = ready;

    if (ready) {
      this._tasksQueue.autoStart = true;
      this.enqueue(() => {});
    }
  }

  public enqueue<T, A extends any[]>(callback: (...args: any[]) => T, args?: A): void {
    if (isPromise(callback)) {
      this._tasksQueue.enqueue(callback as PromiseFunction<T, A>, args);
      return;
    }

    this._tasksQueue.enqueue(syncFunctionToAsyncFunction(callback), args);
  }

  protected logError(message: string, ...args: unknown[]): void {
    console.error(`[${this.name}] ${message}.`, ...(args || []));
  }
}

export { Destination };

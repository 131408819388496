import { StorageStrategy } from "../abstracts";
import { GetError, HasError, RemoveError, SetError } from "../errors";
import { Sentry } from "~/decorators/sentry";

class MemoryStorageStrategy extends StorageStrategy {
  private _cache: Map<string, any> = new Map();

  @Sentry(GetError("MemoryStorageStrategy"))
  public get<U>(key: string): U {
    return this._cache.get(key);
  }

  @Sentry(HasError("MemoryStorageStrategy"))
  public has(key: string): boolean {
    return this._cache.has(key);
  }

  @Sentry(RemoveError("MemoryStorageStrategy"))
  public remove(key: string): MemoryStorageStrategy {
    this._cache.delete(key);

    return this;
  }

  @Sentry(SetError("MemoryStorageStrategy"))
  public set<U>(key: string, data: U): MemoryStorageStrategy {
    this._cache.set(key, data);

    return this;
  }
}

export { MemoryStorageStrategy };

import { Builder } from "builder-pattern";

import { StateModel } from "./models";
import { State } from "./types";

class States {
  private _states: StateModel[] = [];

  public constructor() {
    for (const state of Object.entries(State)) {
      this._addState(Builder(StateModel).name(state[1]).abbreviation(state[0]).build());
    }
  }

  public get states(): StateModel[] {
    return this._states;
  }

  private static _sanitizeState(state: string): string {
    return typeof state === "string" ? state.trim().toUpperCase() : state;
  }

  public findState(name: string): StateModel | void {
    return this.findStateByName(name) || this.findStateByAbbreviation(name);
  }

  public findStateByName(name: string): StateModel | void {
    return this._states.find((state) => States._sanitizeState(state.name) === States._sanitizeState(name));
  }

  public findStateByAbbreviation(abbreviation: string): StateModel | void {
    return this._states.find(
      (state) => States._sanitizeState(state.abbreviation) === States._sanitizeState(abbreviation)
    );
  }

  private _addState(newState: StateModel): void {
    this._states.push(newState);
  }
}

const StatesService: States = new States();

export { StatesService };

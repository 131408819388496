import cloneDeep from "lodash/cloneDeep";
import parseAsString from "lodash/toString";

import { InterceptorModel } from "../../models";
import { InterceptorCategory, InterceptorPayload, InterceptorType } from "../../types";

import { RevisionRepositoryService } from "~/services/RevisionRepository";

const appInfoRequest: InterceptorModel = new InterceptorModel({
  category: InterceptorCategory.APP_INFO,
  type: InterceptorType.REQUEST,
  callback: <T>({ request }: InterceptorPayload<T>): InterceptorPayload<T> => {
    const newRequest: RequestInit = cloneDeep(request);

    newRequest.headers = new Headers(newRequest?.headers);
    newRequest.headers.set(
      "App-Build-Timestamp",
      parseAsString(RevisionRepositoryService.module.currentRevision.buildTimestamp)
    );
    newRequest.headers.set("App-Outdated", parseAsString(RevisionRepositoryService.module.isAppOutdated));
    newRequest.headers.set("Git-Commit-Head", RevisionRepositoryService.module.currentRevision.commitHead);

    return { request: newRequest };
  },
});

export { appInfoRequest };

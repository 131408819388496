import { differenceInYears, format } from "date-fns";

import parseAsNumber from "lodash/toNumber";

import type { EventPayload } from "../types";

import type { UserModel } from "~/services/ApiUser/models";

import { cleanObject } from "~/utils/filters/cleanObject";

const getTraitsFromUserData = (user: UserModel): EventPayload["traits"] => {
  if (!user || !user?.id?.length) {
    return {} as EventPayload["traits"];
  }

  const createdAt: string = user?.createdAt?.length
    ? new Date(parseAsNumber(user?.createdAt) * 1000).toISOString()
    : undefined;

  const isGuardian: boolean = !["unknown", "self"].includes(user?.patient?.relationshipToUserEnum);

  const dateOfBirthDate: Date | void = !user?.patient?.dateOfBirth?.length
    ? undefined
    : new Date(user?.patient?.dateOfBirth);

  const age: number | void = dateOfBirthDate ? differenceInYears(new Date(), dateOfBirthDate) : undefined;
  const phone: string =
    user?.patient?.phone?.length && user.patient.phone !== "not provided" ? user.patient.phone : undefined;

  const address: EventPayload["traits"]["address"] = cleanObject({
    city: (user?.patient?.currentAddress?.city || "")?.trim(),
    country: (user?.patient?.currentAddress?.zipcode?.length ? "US" : "")?.trim(),
    postalCode: (user?.patient?.currentAddress?.zipcode || "")?.trim(),
    state: (user?.patient?.currentAddress?.state || "")?.trim(),
    street: (user?.patient?.currentAddress?.address || "")?.trim(),
  });

  const result: EventPayload["traits"] = cleanObject({
    id: user?.id,
    createdAt,
    email: user?.email,
    phone,

    firstName: user?.firstName || (isGuardian ? user?.patient?.guardianFirstName : user?.patient?.firstName),
    lastName: user?.lastName || (isGuardian ? user?.patient?.guardianLastName : user?.patient?.lastName),

    gender: isGuardian ? undefined : user?.patient?.genderEnum,

    age: isGuardian || !age ? undefined : age,
    birthday: isGuardian || !dateOfBirthDate ? undefined : format(dateOfBirthDate, "yyyy-MM-dd"), // YYYY-MM-DD format
  });

  if (Object.values(address).filter(Boolean).length) {
    result.address = address;
  }

  return result;
};

export { getTraitsFromUserData };

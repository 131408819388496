enum Color {
  BLUE = "blue",
  DANGER = "danger",
  DARK = "dark",
  DARK_BLUE = "dark-blue",
  DARK_GREEN = "dark-green",
  DARK_GREY = "dark-grey",
  DARK_PINK = "dark-pink",
  GREEN = "green",
  GREY = "grey",
  LIGHT = "light",
  LIGHT_BLUE = "light-blue",
  LIGHT_GREEN = "light-green",
  LIGHT_GREY = "light-grey",
  MEDIUM_YELLOW = "medium-yellow",
  LIGHT_YELLOW = "light-yellow",
  MEDIUM_GREY = "medium-grey",
  OFF_WHITE = "off-white",
  NAVY = "navy",
  PINK = "pink",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  TRANSPARENT = "transparent",
  WARM = "warm",
  WHITE = "white",
  YELLOW = "yellow",
}

// temp hack: should be able to extract colors from scss
const HexColor: { [key: string]: string } = {
  [Color.BLUE]: "#0A9CFF", // primary
  [Color.DANGER]: "#BD4274",
  [Color.DARK]: "#262f35",
  [Color.DARK_BLUE]: "#0573BE",
  [Color.DARK_GREEN]: "#058130",
  [Color.DARK_GREY]: "#c0c2ca",
  [Color.DARK_PINK]: "#BD4274",
  [Color.GREEN]: "#0DCE52",
  [Color.GREY]: "#cdd0d8",
  [Color.LIGHT]: "#ecf2f5",
  [Color.LIGHT_BLUE]: "#57BBFF",
  [Color.LIGHT_GREEN]: "#4ADC7E",
  [Color.LIGHT_GREY]: "#f7f8fa",
  [Color.MEDIUM_YELLOW]: "#FFFF9B",
  [Color.LIGHT_YELLOW]: "#FFFFD3",
  [Color.MEDIUM_GREY]: "#c0c2ca",
  [Color.OFF_WHITE]: "#f1f4f5",
  [Color.PINK]: "#FF5C9E",
  [Color.NAVY]: "#40535e",
  [Color.PRIMARY]: "#0573BE",
  [Color.SECONDARY]: "#FF5C9E",
  [Color.SUCCESS]: "#28a745",
  [Color.TRANSPARENT]: "transparent",
  [Color.WARM]: "#ca9376",
  [Color.WHITE]: "#ffffff",
  [Color.YELLOW]: "#FF3",
};

export { Color, HexColor };

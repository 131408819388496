// https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
const toBinary = (value: string): string => {
  const codeUnits = new Uint16Array(value.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = value.charCodeAt(i);
  }

  const charCodes = new Uint8Array(codeUnits.buffer);

  let result: string = "";
  for (let i = 0; i < charCodes.byteLength; i++) {
    result += String.fromCharCode(charCodes[i]);
  }

  return result;
};

export { toBinary };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const FormatDataOnGetError: SentryError = new SentryError()
  .setName("StorageStrategy")
  .setMessage("Parse data from store failed")
  .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
  .addFingerprint("StorageStrategy.formatDataOnGet");

export { FormatDataOnGetError };

import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const RemoveError: (storageName: string) => SentryError = (storageName: string): SentryError => {
  return new SentryError()
    .setName(storageName)
    .setMessage("Remove data from storage has failed")
    .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
    .addFingerprint(`${storageName}.remove`);
};

export { RemoveError };

import { Contexts, Extras, Primitive, RequestSession, ScopeContext, Severity, User } from "../types";
import { SentryUserModel } from "./SentryUserModel";

class SentryContextModel implements ScopeContext {
  public constructor(
    public user: User = new SentryUserModel(),
    public level: Severity = Severity.Error,
    public extra: Extras = {},
    public contexts: Contexts = {},
    public tags: {
      [key: string]: Primitive;
    } = {},
    public fingerprint: string[] = [],
    public requestSession: RequestSession = {}
  ) {}
}

export { SentryContextModel };

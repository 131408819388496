import { SentryError } from "~/services/sentry/SentryError";
import { SentryScope, SentryTagKey } from "~/services/sentry/types";

const HasError: (storageName: string) => SentryError = (storageName: string): SentryError => {
  return new SentryError()
    .setName(storageName)
    .setMessage("Checking if storage contains data has failed")
    .addTag(SentryTagKey.SCOPE, SentryScope.STORE)
    .addFingerprint(`${storageName}.has`);
};

export { HasError };
